import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import typographySizes from 'src/assets/styles/typographySizes.js'

const TitleH1 = styled.h1`
  position: relative;
  color: ${colors.white};
  font-size: ${typographySizes.l}rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
`

export default TitleH1
