import React, { useEffect, useState } from 'react'

import styled, { keyframes } from 'styled-components'
import colors from 'src/assets/styles/colors.js'
import dot from 'src/assets/svg/dot.svg'
import mediaQuery from 'src/assets/styles/mediaQuery'

const Wrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  top: 0;
`

const moveLine = keyframes`
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, -736rem);
  }
`

const MainLine = styled.div`
  height: 200%;
  width: 3rem;

  background-image: url(${dot});
  position: absolute;

  ::after {
    content: '';
    position: absolute;
    left: 1rem;
    width: 1rem;
    height: 100%;
    background-color: ${colors.white};
    opacity: 0.5;
  }

  &.LeftMarginLine {
    left: 0rem;
  }
  &.LeftLine {
    left: calc(16.66% - 2rem);
  }

  &.LeftCenterLine {
    left: calc(33.33% - 2rem);
  }

  &.CenterLine {
    left: 50%;
  }
  &.RightCenterLine {
    left: calc(66.66% - 2rem);
  }

  &.RightLine {
    left: calc(83.33% - 2rem);
  }

  &.RightMarginLine {
    right: 0%;
  }

  &.move {
    animation: ${moveLine} 2.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

const MobileMainLine = styled(MainLine)`
  display: inline-block;
  @media (min-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

const Lines = props => {
  const [randomLine, setRandomLine] = useState(Math.floor(Math.random() * 7))

  useEffect(() => {
    const interval = setInterval(() => {
      setRandomLine(Math.floor(Math.random() * 7))
    }, 3500)
    return () => clearInterval(interval)
  }, [])

  return (
    <Wrapper>
      {props.leftMargin && (
        <MainLine
          className={
            randomLine === 0 ? 'LeftMarginLine move' : 'LeftMarginLine '
          }
        />
      )}
      {props.left && (
        <MainLine
          className={randomLine === 1 ? 'LeftLine move' : 'LeftLine '}
        />
      )}
      {props.leftCenter && (
        <MainLine
          className={
            randomLine === 2 ? 'LeftCenterLine move' : 'LeftCenterLine '
          }
        />
      )}
      {props.center && (
        <MainLine
          className={randomLine === 3 ? 'CenterLine move' : 'CenterLine '}
        />
      )}
      {props.rightCenter && (
        <MainLine
          className={
            randomLine === 4 ? 'RightCenterLine move' : 'RightCenterLine '
          }
        />
      )}
      {props.right && (
        <MainLine
          className={randomLine === 5 ? 'RightLine move' : 'RightLine '}
        />
      )}
      {props.rightMargin && (
        <MainLine
          className={
            randomLine === 6 ? 'RightMarginLine move' : 'RightMarginLine '
          }
        />
      )}
      <MobileMainLine className={'LeftMarginLine '} />
      {/* <MainLine className={'CenterLine '} /> */}
      <MobileMainLine className={'RightMarginLine '} />
    </Wrapper>
  )
}

export default Lines
