import React, { useRef, useEffect } from 'react'

import styled from 'styled-components'
import gsap from 'gsap'

import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'

const RotateWrapper = styled.div`
  width: calc(100%);
  position: relative;
  z-index: 10;
  overflow: hidden;
`

const ModuleWrapper = styled.span`
  width: calc(100%);
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  margin-top: 0rem;
`

const Module = styled.span`
  display: flex;
  align-items: center;

  span {
    color: ${colors.black};
    font-size: ${typographySizes.xl}rem;
    background-color: ${colors.secColor};
    font-weight: 700;
    position: relative;

    white-space: nowrap;

    display: inline-block;
    padding: 10rem 50rem 0 50rem;
  }
`

const TextBarAnimationBig = ({ children }) => {
  const wrapper = useRef(null)

  useEffect(() => {
    const movmentTimeline = gsap.timeline({
      repeat: -1,
    })
    const element = wrapper.current

    const bounds = element.children[0].getBoundingClientRect()
    const w = bounds.width

    movmentTimeline
      .set(element, { x: 0 })
      .to(element, { x: -w, duration: w * 0.01, ease: 'linear' })
  })

  return (
    <RotateWrapper>
      <ModuleWrapper ref={wrapper}>
        {[...Array(10)].map((x, i) => (
          <Module key={i}>
            <span> {children}</span>{' '}
          </Module>
        ))}
      </ModuleWrapper>
    </RotateWrapper>
  )
}

export default TextBarAnimationBig
